import React from 'react'
import { Grid, Button } from 'semantic-ui-react'
import classnames from 'classnames'
import Vimeo from 'react-player/vimeo'
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon
} from 'react-share'

import SEO from '../components/SEO'
import Logo from '../images/logos/contego-logo.png'
import Jetliner from '../images/general/jet-liner.png'
import ReusableJet from '../images/general/reusable-jet.jpeg'

const WhyDisposableJet = () => {
  const [disposableJetActive, setDisposableJetActive] = React.useState(false)
  const [reusableJetActive, setReusableJetActive] = React.useState(false)

  const handleClick = e => {
    const { value } = e.currentTarget.dataset

    switch (value) {
      case 'disposable-jet':
        setDisposableJetActive(disposableJetActive => !disposableJetActive)
        setReusableJetActive(false)
        break
      default:
        setReusableJetActive(reusableJetActive => !reusableJetActive)
        setDisposableJetActive(false)
    }
  }

  return (
    <div className="container-fluid">
      <SEO
        title="Why Disposable Jet?"
        description="Reasons why you should look for disposable jet"
        meta={[
          {
            name: 'keywords',
            content: 'reusable jet, disposable jet, pedicure'
          }
        ]}
      />

      <div className="row">
        <div className="col">
          <Grid columns="1" stackable className="py-4">
            <Grid.Column verticalAlign="middle">
              <img
                src={Logo}
                alt="contego logo"
                className="mx-auto d-block"
                width="150px"
                height="auto"
              />
            </Grid.Column>
          </Grid>

          <br />

          <Grid columns="1" className="pb-4">
            <Grid.Column>
              <h1 className="text-center mb-0">
                <strong className="text-secondary"> PEDICURE SERVICE ANGELS</strong>
              </h1>

              <p className="text-center px-4">
                CONTÉGO Disposable Jet protects your feet from cross-contamination
              </p>
            </Grid.Column>
          </Grid>

          <Grid stackable columns="1" padded className="my-4 py-4 rounded">
            <Grid.Column>
              <h2>DO YOU KNOW HOW THE REUSABLE JET WORKS?</h2>

              <p>A real threat to sanitation practices</p>

              <br />

              <div className="player-wrapper">
                <Vimeo
                  className="react-player"
                  url="https://player.vimeo.com/video/437274985"
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            </Grid.Column>
          </Grid>

          <br />

          <Grid stackable columns="16" padded className="my-4 bg-light rounded">
            <Grid.Column
              widescreen="4"
              largeScreen="4"
              computer="4"
              tablet="16"
              mobile="16"
              className="d-flex flex-column justify-content-center"
            >
              <h2>WHY REUSABLE JETS CAUSE PROBLEMS</h2>

              <p>
                Improper cleaning of Reusable Jets create an ideal environment for bacteria and
                fungi to live and spread from one session to another.
              </p>
            </Grid.Column>

            <Grid.Column widescreen="12" largeScreen="12" computer="12" tablet="16" mobile="16">
              <div className="player-wrapper">
                <Vimeo
                  className="react-player"
                  url="https://player.vimeo.com/video/433678040"
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            </Grid.Column>
          </Grid>

          <Grid stackable columns="1" padded className="my-4 py-4 rounded">
            <Grid.Column>
              <h2 className="mb-0">
                CONTÉGO’S 100% <strong className="text-secondary">DISPOSABLE JET</strong>
              </h2>
              <h2 className="mt-0">IS THE SOLUTION</h2>
              <p>
                The 100% Disposable Jet is a single use jet which will be disposed of after each
                session. You will never worry about fungus or bacteria again!
              </p>

              <br />

              <div className="player-wrapper">
                <Vimeo
                  className="react-player"
                  url="https://player.vimeo.com/video/433679019"
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            </Grid.Column>
          </Grid>

          <Grid columns="1" padded className="my-4 py-4 rounded bg-light">
            <Grid.Column>
              <h2>PEOPLE FEEL SAFE USING CONTÉGO&lsquo;S DISPOSABLE JET</h2>

              <p>
                People share their experience after choosing Contégo’s Disposable Jet for their
                pedicure session.
              </p>

              <div className="vimeo-video-container">
                <iframe
                  title="Disposable Jet"
                  src="https://player.vimeo.com/video/433493078"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullscreen
                  className="rounded vimeo-video"
                />
              </div>
            </Grid.Column>
          </Grid>

          <Grid columns="1" className="pb-4">
            <Grid.Column>
              <h2 className="text-secondary">
                Reusable Jet vs. Disposable Jet - which would you prefer for your next pedicure
                service?
              </h2>

              <div className="d-flex justify-content-between">
                <Button
                  data-value="disposable-jet"
                  onClick={handleClick}
                  className={classnames([
                    'selection-btn-sm shadow mr-4 my-2',
                    disposableJetActive ? 'active' : 'inactive'
                  ])}
                >
                  <img
                    src={Jetliner}
                    alt="disposable jet"
                    className="mx-auto d-block"
                    width="100px"
                    height="auto"
                  />
                  Disposable Jet
                </Button>

                <Button
                  data-value="reusable-jet"
                  onClick={handleClick}
                  className={classnames([
                    'selection-btn-sm shadow mr-4 my-2 float-right',
                    reusableJetActive ? 'active' : 'inactive'
                  ])}
                >
                  <img
                    src={ReusableJet}
                    alt="reusable jet"
                    className="mx-auto d-block"
                    width="100px"
                    height="auto"
                  />
                  Reusable Jet
                </Button>
              </div>

              <h1 className="text-blue">
                If you care for your family and friend&lsquo;s pedicure safety, please share this
                valuable message.
              </h1>

              <FacebookShareButton
                url="https://www.contegospa.com/why-disposable-jet"
                quote="CONTÉGO Disposable Jet for your pedicure safety!!!"
                hashtag="#Contegospa #SafePedicure"
              >
                <FacebookIcon size={69} round />
              </FacebookShareButton>

              <FacebookMessengerShareButton url="https://www.contegospa.com/why-disposable-jet">
                <FacebookMessengerIcon size={69} round />
              </FacebookMessengerShareButton>

              <TwitterShareButton
                url="https://www.contegospa.com/why-disposable-jet"
                title="CONTÉGO Disposable Jet for your pedicure safety!!!"
                hashtag="#Contegospa #SafePedicure"
              >
                <TwitterIcon size={69} round />
              </TwitterShareButton>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default WhyDisposableJet
